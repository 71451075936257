import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const CrowdLogin = Loadable(lazy(() => import('app/views/sessions/CrowdLogin')));

// dashboard page
const ShowrunnerPage = Loadable(lazy(() => import('app/views/dashboard/ShowrunnerPage/ShowrunnerPage')));
const ShowrunnerEmailPage = Loadable(lazy(() => import('app/views/dashboard/ShowrunnerEmailPage/ShowrunnerEmailPage')));
const ClientHostingPage = Loadable(lazy(() => import('app/views/dashboard/ClientHostingPage/ClientHostingPage')));
const OpsApplePage = Loadable(lazy(() => import('app/views/dashboard/OpsApplePage/OpsApplePage')));
const PrecisionPage = Loadable(lazy(() => import('app/views/dashboard/PrecisionPage/PrecisionPage')));


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      {
        path: '/dashboard/showrunner',
        element: <ShowrunnerPage />,
      },
      {
        path: '/dashboard/showrunner-notifications',
        element: <ShowrunnerEmailPage />,
      },
      // client-hosting website route
      {
        path: '/dashboard/client-hosting',
        element: <ClientHostingPage />,
      },
      // Apple set-top box route
      {
        path: '/dashboard/ops-apple',
        element: <OpsApplePage />,
      },
      // Precision route
      {
        path: '/dashboard/Precision',
        element: <PrecisionPage />,
      },
    ]
  },

  { path: '/', element: <Navigate to="dashboard/showrunner" /> },
  { path: '/session/signin', element: <CrowdLogin /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
