import { createContext, useEffect, useReducer } from 'react';
import { signInWithRedirect, signOut, getCurrentUser } from 'aws-amplify/auth'
import { MatxLoading } from 'app/components';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'AWS',
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async () => {
    try {
      const user = await signInWithRedirect();
      dispatch({ type: 'LOGIN', payload: { user } });
    } catch (error) {
      console.error('Error during sign in:', error);
    }
  };

  const logout = async () => {
    try {
      await signOut();
      dispatch({ type: 'LOGOUT' });
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const user = await getCurrentUser();
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user } });
      } catch (err) {
        console.error('Error during initial auth check:', err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'AWS', login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
